*{ box-sizing: border-box; }
html, body{
	margin:0; padding:0;
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 14px;
	text-align: left;
	color: #2c3e50;
}


/* layout */
.layout{
	&_menu{}
	&_content{
		padding-top: 20px;
	}
}


/* page */
.page_crud{
	display: flex;
	align-items: stretch;
	&__list, &__form{
		width: 50%;
		padding: 0px 10px;
	}
}